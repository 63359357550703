import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import SalesConversionService from "./sales-order-conversion-service";
import commonApiService from "../../common/js/common-api-service";

export default {
  data: () => ({
    name: "Sales Order Conversion Matrix",
    mainPage: "",
    subPage: "",
    pageUrl: "",
    backSlash: true,
    selectedProject: "",
    favouriteList: [],
    showAdd: false,
    projectList: [],
    editArray: [],
    userId: EncryptUtility.localStorageDecrypt("userID"),
    projectSelected: true,
    refresh: true,
    totalRecords: 0,
    columnValue: 0,
    salesOrderMatrix: [],
    popupTitleParent: "",
    itemStatusSODialog: false,
    header: "",
    SOMatrixTable: false,
    headers: [
      { text: "FROM/TO", value: "headerValue", class: "primary customwhite--text" },
      { text: "SS", value: "SS", class: "primary customwhite--text" },
      { text: "CR", value: "CR", class: "primary customwhite--text" },
      { text: "AX", value: "AX", class: "primary customwhite--text" },
      { text: "EX", value: "EX", class: "primary customwhite--text" },
      { text: "RN", value: "RN", class: "primary customwhite--text" },
    ],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.getProject();
  },

  methods: {
    //Project Details
    async getProject() {
      let projectData = await commonApiService.getProject("get",this.userId);
      this.projectList = projectData.ProjectInfo;
    },

    //Get Page Action and name
    //On Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter(
          (entry) => entry.SystemName === action
        );
        return filter !== undefined && filter !== null && filter.length == 1
          ? true
          : false;
      } else return false;
    },
    //Grid Binding
    async SalesOrderMatrixSearch() {
      this.salesOrderMatrix = [];
      let searchObj = {
        UserID: parseInt(this.userId),
        Pass: 2,
        proj_id: parseInt(this.selectedProject),
      };
      let searchData = await SalesConversionService.postSalesOrderMatrixSearch(
        "post",
        searchObj
      );
      if (searchData.message != "NA") {
        this.salesOrderMatrix = searchData;
      } else {
        this.salesOrderMatrix = [];
      }
      this.totalRecords = this.salesOrderMatrix.length;
    },
    onProjectSelect() {
      this.SOMatrixTable = true;
      this.SalesOrderMatrixSearch();
    },
    //Reset Function
    resetFunction() {
      this.selectedProject = "";
      this.projectList = [];
      this.getProject();
      this.SOMatrixTable = false;
      this.salesOrderMatrix = [];
      this.totalRecords = 0;
    },
    //Cancel Event
    onClickCancel() {
      this.itemStatusSODialog = false;
      this.SalesOrderMatrixSearch();
    },
    //on chnage of status
    changeSalesOrderMatrix(item, header) {
      this.editArray = [];
      this.itemStatusSODialog = true;
      this.editArray = item;
      this.header = header;
      this.popupTitleParent =
        "Are you sure you want to enable/disable this item?";
    },
    itemStatusChange() {
      this.editSalesOrderMatrix(this.editArray, this.header);
    },
    //update the status
    editSalesOrderMatrix(item, header) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      if (header == "AX") {
        this.columnValue = 3;
      } else if (header == "SS") {
        this.columnValue = 1;
      } else if (header == "CR") {
        this.columnValue = 2;
      } else if (header == "EX") {
        this.columnValue = 4;
      } else if (header == "RN") {
        this.columnValue = 5;
      }
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let EditObject = {
        pass: 3,
        proj_id: this.selectedProject,
        userid: parseInt(this.userId),
        sotype_id: item.sotype_key,
        column: this.columnValue,
      };
      this.axios
        .post("/so/so_conversion_matrix", EditObject)
        .then((response) => {
            if (response.status == 200 || response.status == 204) {
            this.itemStatusSODialog = false;
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.SalesOrderMatrixSearch();
            this.$store.commit("ConfigModule/Alert", Alert);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
  },
  components: {
    breadcrumbComp,
  },
};
